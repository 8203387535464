import { createRouter, createWebHistory } from "vue-router";
import DashboardLayout from "@/layouts/DashboardLayout.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { auth } from "../services/db.js";

const needsAuth = true;

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/signin",
    name: "Sign In",
    component: () =>
      import(/* webpackChunkName: "signin" */ "../views/SignIn.vue"),
  },
  {
    path: "/resetPwd",
    name: "Reset Password",
    component: () =>
      import(/* webpackChunkName: "resetPwd" */ "../views/ResetPwd.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Register.vue"),
  },
  {
    path: "/account",
    name: "Account",
    component: () =>
      import(/* webpackChunkName: "account" */ "../views/AccountInfo.vue"),
    meta: {
          requiresAuth: needsAuth,
        },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: '/accept_invite/:invite_id/:firm_id',
    name: 'InvitePage',
    component: () =>
      import(/* webpackChunkName: "invite" */ "../views/AcceptInvite.vue"),
  },
  {
    path: "/dashboard",
    component: DefaultLayout,
    children: [
      {
        path: "",
        name: "User Dashboard",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
        meta: {
          requiresAuth: needsAuth,
        },
      },
      {
        path: "profile",
        name: "Profile",
        component: () =>
          import(/* webpackChunkName: "profile" */ "../views/Profile.vue"),
        meta: {
          requiresAuth: needsAuth,
        },
      },
    ],
  },
  {
    path: "/create-firm",
    name: "Create Firm",
    component: DefaultLayout,
    children: [
      {
        path: "",
        name: "Create Firm",
        component: () =>
          import(
            /* webpackChunkName: "firm" */ "../views/firm/firm-creation/NewFirm.vue"
          ),
        meta: {
          requiresAuth: needsAuth,
        },
      },
      {
        path: "created",
        name: "Firm Created",
        component: () =>
          import(
            /* webpackChunkName: "firm" */ "../views/firm/firm-creation/FirmCreated.vue"
          ),
        meta: {
          requiresAuth: needsAuth,
        },
      },
    ],
  },
  {
    path: "/firm/:id",
    name: "Firm",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "firm" */ "../views/firm/FirmDashboard.vue"
          ),
        meta: {
          requiresAuth: needsAuth,
        },
      },
      {
        path: "settings",
        name: "Firm Settings",
        component: () =>
          import(
            /* webpackChunkName: "firm" */ "../views/firm/FirmSettings.vue"
          ),
        meta: {
          requiresAuth: needsAuth,
        },
      },
      {
        path: "clients",
        name: "Clients",
        component: () =>
          import(/* webpackChunkName: "firm" */ "../views/firm/AllClients.vue"),
        meta: {
          requiresAuth: needsAuth,
        },
      },
      {
        path: "patents",
        name: "Patent Applications",
        component: () =>
          import(/* webpackChunkName: "firm" */ "../views/firm/Patents.vue"),
        meta: {
          requiresAuth: needsAuth,
        },
      },
      {
        path: "patents/:patentId/:tab?",
        name: "Patent Application",
        component: () =>
          import(
            /* webpackChunkName: "firm" */ "../views/firm/IndividualPatent.vue"
          ),
        meta: {
          requiresAuth: needsAuth,
        },
      },
      {
        path: "members",
        name: "Members",
        component: () =>
          import(/* webpackChunkName: "firm" */ "../views/firm/Team.vue"),
        meta: {
          requiresAuth: needsAuth,
        },
      },
      {
        path: "office-actions",
        name: "Office Actions",
        component: () =>
          import(
            /* webpackChunkName: "firm" */ "../views/firm/OfficeActions.vue"
          ),
        meta: {
          requiresAuth: needsAuth,
        },
      },
      // {
      //   path: "notifications",
      //   name: "Notifications",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "firm" */ "../views/firm/Notifications.vue"
      //     ),
      //   meta: {
      //     requiresAuth: needsAuth,
      //   },
      // },
      {
        path: "docketing",
        name: "Docketing",
        component: () =>
          import(/* webpackChunkName: "firm" */ "../views/firm/Docketing.vue"),
        meta: {
          requiresAuth: needsAuth,
        },
      },
      {
        path: "trademarks",
        name: "Trademarks",
        component: () =>
          import(/* webpackChunkName: "firm" */ "../views/firm/TrademarkMain.vue"),
        meta: {
          requiresAuth: needsAuth,
        },
      },
      {
        path: "help",
        name: "Help",
        component: () =>
          import(/* webpackChunkName: "help" */ "../views/firm/Help.vue"),
      },
    ],
  },
  {
    path: '/:catchAll(.*)*',
    component: () =>
      import(/* webpackChunkName: "help" */ "../views/NotFoundComponent.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

let firstRoute = false;

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  if (requiresAuth && !auth.currentUser && firstRoute) {
    next("/signin");
  } else if (!firstRoute) {
    next();
    firstRoute = true;
  } else {
    next();
  }
});

export default router;
