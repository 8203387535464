import { db } from "./db.js";
import { ref } from "vue";

// ==================== READ ====================

function getMemberCount(firmId) {
  return db.collection(`firms/${firmId}/members`).where('status', '==', 'joined').count().get().then((snapshot) => {
    return snapshot.data().count;
  }) ;
}

function getInviteCount(firmId) {
  return db.collection(`firms/${firmId}/invites`).where('status', '==', 'invited').count().get().then((snapshot) => {
    return snapshot.data().count;
  }) ;
}

async function getMembers(firmId) {
  const members = ref([]);
  const snapshot = await db.collection(`firms/${firmId}/members`).get();
  members.value = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

  return members;
}

function getNonAdminMembers(firmId) {
  const members = ref([]);
  db.collection(`firms/${firmId}/members`).where("role", "!=", "admin").get().then(
    (snapshot) => {
      members.value = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    }
  );
  return members;
}

async function getMember(firmId, memberId) {
  const member = await db.doc(`firms/${firmId}/members/${memberId}`).get();
  return member.exists ? member.data() : null;
}

async function getInviteByEmail(firmId, email) {
  const member = await db
    .collection(`firms/${firmId}/invites`)
    .where("email", "==", email)
    .get();

  if (member.docs) {
    return member.docs[0].data();
  }
  return null;
}

// ==================== UPDATE ====================

function updateMember(firmId, memberId, data) {
  return db
    .doc(`firms/${firmId}/members/${memberId}`)
    .set(data, { merge: true });
}

// ==================== DELETE ====================

export {
  getMembers,
  getMember,
  getMemberCount,
  getInviteCount,
  getInviteByEmail,
  getNonAdminMembers,
  updateMember,
};
