<template>
  <router-view></router-view>
</template>

<script>
export default {
  mounted() {
    console.log("defaullayout.vue called ...");
  },
};
</script>
