import { createApp } from "vue";
import VueCookies from "vue3-cookies";
import App from "./App.vue";
import router from "./router/index.js";
import "./assets/tailwind.css";
import "./assets/editor.css";
import LitepieDatepicker from "litepie-datepicker";
import { createPinia } from 'pinia';


createApp(App)
  .use(router)
  .use(VueCookies)
  .use(LitepieDatepicker)
  .use(createPinia())
  .mount("#app");
