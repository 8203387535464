<template>
  <div v-if="isLoading" class="h-screen flex items-center justify-center">
    <h1>Patensys is Loading</h1>
  </div>
  <router-view v-if="!isLoading"></router-view>
</template>
<script>
import { auth } from "@/services/db.js";
import { getUser } from "@/services/user.js";
import { isUserInFirm } from "@/services/firm.js";
import { setAuthToken } from "./services/backend-api";
export default {
  data() {
    return {
      isLoading: true,
    };
  },
  methods: {
    redirectIfLoggingIn(firm) {
      if (['/signin', '/register'].includes(this.$router.currentRoute.value.path)) {
        if (firm) {
          this.$router.push(`/firm/${firm}`);
        } else {
          this.$router.push('/dashboard');
        }
      }
    },
  },
  async mounted() {
    console.log("App.vue is called ... ");
    if (auth.currentUser) {
      console.log("user is logged in");
        auth.currentUser.getIdToken().then(function(idToken) {
          setAuthToken(idToken);
        });
    }
    auth.onIdTokenChanged((user) => {
      if (user) {
        console.log("token changed");
        user.getIdToken().then(function(idToken) {
          setAuthToken(idToken);
        });
      }
    });
    //On login this seems to be called before the user has firebase permissions.
    auth.onAuthStateChanged(async (user) => {
      let userData;
      if (user) {
        if (user.emailVerified) {
          console.log("in auth getting token" + user.uid);
          const idToken = await user.getIdToken();

          setAuthToken(idToken);
          //TODO double check that this is the best way to store auth state.
          this.$cookies.set("uid", user.uid, Infinity);
          userData = await getUser(user.uid);

          let defaultFirm = this.$cookies.get("defaultFirm");
          if (defaultFirm) {
            if (userData && userData.defaultFirm == undefined || userData.defaultFirm == null) {
              this.$cookies.remove("defaultFirm");
              defaultFirm = null;
            }
          }
          this.isLoading = false;
          if (userData && !userData.firstName && user) {
            this.$router.push("/account");
          } else if (defaultFirm) {
            const inFirm = await isUserInFirm(user.uid, defaultFirm);
            if (!inFirm) {
              this.$router.push(`/dashboard`);
            } else if (this.$route.params.id && this.$route.params.id != defaultFirm) {
              this.$cookies.set("defaultFirm", this.$route.params.id, Infinity);
            }
            this.redirectIfLoggingIn(defaultFirm);
          } else {
            this.$router.push('/dashboard');
          }
        } else {
          this.isLoading = false;
          this.$router.push('/register');
        }
      } else {
        this.isLoading = false;
        this.$router.push("/signin");
      }

    });
  },
};
</script>
