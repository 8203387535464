import { db, generateId } from "./db.js";
import { ref } from "vue";
import { getUser } from "./user.js";
import { sendInvites } from "./backend-api.js";
import { getSubscription } from "./subscription.js";

// ==================== CREATE ====================

function createFirm(data, userId, invited) {
  data.owner = userId;

  //TODO make sure owner is included in data in security rules.

  const firmPromise = db.collection("firms").add(data);

  return firmPromise.then((firm) => {
    const promises = [];
    const admin = getUser(userId).then((user) => {
      return db.doc(`firms/${firm.id}/members/${userId}`).set({
        uid: userId,
        role: "admin",
        status: "joined",
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
      });
    });
    const adminUser = db.doc(`users/${userId}/firms/${firm.id}`).set({
      firmId: firm.id,
      name: data.name,
      role: "admin",
    });
    promises.push(adminUser);
    promises.push(admin);

    const invite_promises = [];
    for (const invite of invited) {
      if (invite.email.length > 0) {
        invite_promises.push(inviteMember(firm.id, invite.role, invite.email));
      }
    }
    if (invite_promises.length > 0) {
      Promise.all(invite_promises).then( ()=> { sendInvites(firm.id); });
    }
    return Promise.all(promises)
      .then(() => { return firm; });
  });
}

// ==================== READ ====================

function getFirmsByUser(userId) {
  const firms = ref([]);
  //TODO with Pinea this needs to clean up the
  db.collection(`users/${userId}/firms`).onSnapshot((snapshot) => {
    firms.value = snapshot.docs.map((doc) => ({ ...doc.data() }));
  }, (error) => {
    console.log("On snapshot error:" + error);
  });
  return firms;
}

async function getFirm(firmId) {
  return db.doc(`firms/${firmId}`)
    .get()
    .then(
      (firm) => {
        return firm.exists ? firm.data() : null;
      });
}

async function getFirmSettings(firmId) {
  const firm = await getFirm(firmId);
  if (firm) {
    return firm.settings ?? {};
  }
  else {
    return null;
  }
}

async function getDefaultFirm(userId) {
  const user = await db.doc(`users/${userId}`).get();
  return user.exists ? user.data().defaultFirm : null;
}

async function isUserInFirm(userId, firmId) {
  const membership = await db.doc(`firms/${firmId}/members/${userId}`).get();
  return(membership && membership.data()["status"] == "joined");
}

// ==================== UPDATE ====================

function updateFirm(firmId, data) {
  return db.doc(`firms/${firmId}`).update(data);
}

function inviteMember(firmId, role, email = "") {
  const id = generateId();
  return db.doc(`firms/${firmId}/invites/${id}`).set({
    id,
    role,
    status: "invited",
    email,
  }).then(() => sendInvites(firmId));
}

function getInvites(firmId) {
  const invites = ref([]);
  db.collection(`firms/${firmId}/invites`).get().then((snapshot) => {
    invites.value = snapshot.docs.map((doc) => ({ ...doc.data() }));
  });
  return invites;
}

// ==================== NO DELETE ALLOWED ====================

// firm docketing rules related methods

async function updateDocketingRule(firmId, docketingRule) {
  const settings = await getFirmSettings(firmId);
  if (settings == null) {
    // this means the firm does not exist
    return null;
  }
  else {
    settings.docketingRules = { ...settings.docketingRules, ...docketingRule }
    return updateFirm(firmId, {settings: settings});
  }
}

async function removeDocketingRule(firmId, code) {
  const settings = await getFirmSettings(firmId);
  if (settings == null) {
    // this means the firm does not exist
    return null;
  }
  else {
    if (settings.docketingRules) {
      delete settings.docketingRules[code];
      return updateFirm(firmId, {settings: settings});
    }
    return null;
  }
}

async function getDocketingRule(firmId, code) {
  const settings = await getFirmSettings(firmId);
  if (settings == null) {
    // this means the firm does not exist
    return null;
  }
  else {
    if (settings.docketingRules) {
      return settings.docketingRules[code];
    }
    return null;
  }
}

async function getDocketingRules(firmId) {
  const settings = await getFirmSettings(firmId);
  if (settings == null) {
    return null;
  }
  else {
    return settings.docketingRules ?? {};
  }
}

async function getIgnoreList(firmId) {
  const settings = await getFirmSettings(firmId);
  if (settings == null) {
    return null;
  }
  else {
    return settings.ignoreList ?? [];
  }
}

async function addCodeToIgnoreList(firmId, documentCode) {
  const settings = await getFirmSettings(firmId);
  if (settings == null) {
    // the firm does not exist
    return null;
  }
  else {
    if (settings.ignoreList) {
      if (!settings.ignoreList.includes(documentCode)) {
        settings.ignoreList.push(documentCode);
      }
    }
    else {
      settings.ignoreList = [documentCode];
    }
    return updateFirm(firmId, {settings: settings});
  }
}

async function removeCodeFromIgnoreList(firmId, documentCode) {
  const settings = await getFirmSettings(firmId);
  if (settings == null) {
    // the firm does not exist
    return null;
  }
  else {
    if (settings.ignoreList) {
      const index = settings.ignoreList.indexOf(documentCode);
      if (index !== -1) {
        settings.ignoreList.splice(index, 1);
      }
    }
    return updateFirm(firmId, {settings: settings});
  }
}

async function updateIgnoreList(firmId, newlist) {
  const settings = await getFirmSettings(firmId);
  if (settings == null) {
    // the firm does not exist
    return null;
  }
  else {
    settings.ignoreList = newlist;
    return updateFirm(firmId, {settings: settings});
  }
}

async function getSubscriptionData(firmId) {
  const firm = await getFirm(firmId);
  if (firm) {
    return await getSubscription(firm.subscriptionId);
  }
  return null;
}

export {
  createFirm,
  getFirmsByUser,
  inviteMember,
  getInvites,
  getDefaultFirm,
  getFirm,
  isUserInFirm,
  updateFirm,
  updateDocketingRule,
  getFirmSettings,
  getDocketingRules,
  getIgnoreList,
  addCodeToIgnoreList,
  removeCodeFromIgnoreList,
  removeDocketingRule,
  updateIgnoreList,
  getDocketingRule,
  getSubscriptionData
};
