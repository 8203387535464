import { db } from "./db.js";

// ==================== CREATE ====================

function createSubscription(data, userId) {
  data.owner = userId;

  return db.collection("subscriptions").add(data);
}

// ==================== READ ====================

async function getSubscription(subId) {
  const subscription = await db.doc(`subscriptions/${subId}`).get();
  return subscription.exists ? subscription.data() : null;
}

// ==================== UPDATE ====================

//TODO security needs to only allow firmId to be updated and user must be owner of both firm and sub.
function updateSubscription(subId, data) {
  return db.doc(`subscriptions/${subId}`).update(data);
}

export { createSubscription, getSubscription, updateSubscription };
