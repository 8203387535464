<template>
  <div v-if="notFound" class="py-6">
    <div class="lg:px-12 px-4 sm:px-6 md:px-8">
      <h1 class="text-3xl text-gray-700 font-bold mt-8 mb-3 text-center">
        Not Found
      </h1>
      <p class="text-center">The page you requested was not found.</p>
      <p class="text-center">Try going <a class="text-purple-600" href="/">Home</a> or check the URL and try again.</p>
    </div>
  </div>
  <div v-else class="h-screen flex overflow-hidden bg-gray-100">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        static
        class="fixed inset-0 flex z-40 md:hidden"
        @close="sidebarOpen = false"
        :open="sidebarOpen"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div
            class="
              relative
              flex-1 flex flex-col
              max-w-xs
              w-full
              pt-5
              pb-4
              bg-white
            "
          >
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  class="
                    ml-1
                    flex
                    items-center
                    justify-center
                    h-10
                    w-10
                    rounded-full
                    focus:outline-none
                    focus:ring-2 focus:ring-inset focus:ring-white
                  "
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex-shrink-0 flex items-center font-bold text-lg text-blue-700 px-5">
              {{ firmName }}
            </div>
            <div class="flex items-center uppercase font-bold text-xs text-gray-400 px-6">
              {{ role }}
            </div>
            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <nav class="px-2 space-y-1">
                <router-link
                  v-for="item in navigation"
                  :key="item.name"
                  :to="`/firm/${firmId}${slash(item.name)}${item.href}`"
                  :class="[
                    current == item.href
                      ? 'bg-gray-100 text-gray-900'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                    'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                  ]"
                >
                  <component
                    :is="item.icon"
                    :class="[
                      current == item.href
                        ? 'text-gray-500'
                        : 'text-gray-400 group-hover:text-gray-500',
                      'mr-4 h-6 w-6',
                    ]"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                </router-link>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:flex-shrink-0">
      <div class="flex flex-col w-64">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div
          class="
            flex flex-col flex-grow
            border-r border-gray-200
            pt-5
            pb-4
            bg-white
            overflow-y-auto
          "
        >
          <div class="flex items-center uppercase font-bold text-lg text-blue-700 px-5">
            {{ firmName }}
          </div>
          <div class="flex items-center uppercase font-bold text-xs text-gray-400 px-6">
            {{ role }}
          </div>
          <div class="mt-5 flex-grow flex flex-col">
            <nav class="flex-1 px-2 bg-white space-y-1">
              <router-link
                v-for="item in navigation"
                :key="item.name"
                :to="`/firm/${firmId}${slash(item.name)}${item.href}`"
                :class="[
                  current == `/firm/${firmId}${slash(item.name)}${item.href}`
                    ? 'bg-gray-100 text-gray-900'
                    : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                  'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                ]"
              >
                <component
                  :is="item.icon"
                  :class="[
                    current == item.href
                      ? 'text-gray-500'
                      : 'text-gray-400 group-hover:text-gray-500',
                    'mr-3 h-6 w-6',
                  ]"
                  aria-hidden="true"
                />
                {{ item.name }}
              </router-link>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <div class="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <button
          class="
            px-4
            border-r border-gray-200
            text-gray-500
            focus:outline-none
            focus:ring-2 focus:ring-inset focus:ring-indigo-500
            md:hidden
          "
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
        </button>
        <div class="flex-1 px-4 flex justify-between">
          <div class="ml-4 flex items-right md:ml-6">
            <!-- Profile dropdown -->
            <Menu as="div" class="m-3 fixed right-0">
              <div class="">
                <MenuButton
                  class="
                    max-w-xs
                    bg-white
                    flex
                    items-center
                    text-sm
                    rounded-full
                    focus:outline-none
                    focus:text-gray-500
                  "
                >
                  <span class="sr-only">Open user menu</span>
                  <Avatar :user="this.user"/>
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="
                    origin-top-right
                    absolute
                    right-0
                    mt-2
                    w-48
                    rounded-md
                    shadow-lg
                    py-1
                    bg-white
                    ring-1 ring-black ring-opacity-5
                    focus:outline-none
                  "
                >
                  <MenuItem
                    v-for="item in userNavigation"
                    :key="item.name"
                    v-on:click="profileFunction(item.name)"
                  >
                    <a
                      :href="item.href"
                      :class="[
                        current == item.href ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]"
                      >{{ item.name }}</a
                    >
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>

      <main
        class="
          flex-1
          mt-5
          relative
          overflow-y-auto
          text-left
          focus:outline-none
        "
      >
        <div class="py-6">
          <div class="lg:px-12 px-4 sm:px-6 md:px-8">
            <h1
              v-if="currentRouteName == 'Dashboard' && firstName"
              class="text-2xl font-semibold text-gray-900"
            >
              Hi, {{ firstName }}
            </h1>
            <h1
              v-else-if="currentRouteName == 'Docketing'"
              class="text-2xl font-semibold text-gray-900"
            >
              Your Docketing Tasks
            </h1>
            <h1
              v-else-if="currentRouteName == 'Office Actions'"
              class="text-2xl font-semibold text-gray-900"
            >
              Office Communications
            </h1>
            <h1
              v-else-if="currentRouteName == 'Firm Settings'"
              class="text-2xl font-semibold text-gray-900"
            ></h1>
            <h1 v-else class="text-2xl font-semibold text-gray-900">
              {{ currentRouteName }}
            </h1>
          </div>
          <ProfileView
            v-if="user"
            :user="user"
            :isOpen="profilePopup"
            v-on:close="closeProfile"
            v-on:saved="user = {...user, ...$event.data}"
          />
          <ChangePassword
            v-if="user"
            :isOpen="passwordPopup"
            v-on:close="passwordPopup = false"
          />
          <div class="lg:px-12 px-4 sm:px-6 md:px-8">
            <!-- Replace with your content -->
            <div class="py-6">
              <router-view></router-view>
            </div>
            <!-- /End replace -->
          </div>
        </div>
        <!-- INSERT PROFILE POPUP -->
      </main>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { logout } from "@/services/user.js";
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  BellIcon,
  CalendarIcon,
  CogIcon,
  FolderIcon,
  OfficeBuildingIcon,
  HomeIcon,
  InboxIcon,
  MenuAlt2Icon,
  UsersIcon,
  XIcon,
  QuestionMarkCircleIcon,
  BadgeCheckIcon
} from "@heroicons/vue/outline";
import { capitalize } from "lodash";
import Avatar from "@/components/general/Avatar.vue";
import ProfileView from "@/components/user-profile/ProfileView.vue";
import { getUser, updateUser } from "@/services/user";
import { getFirm } from "@/services/firm";
import { getMember } from "@/services/teams";
import ChangePassword from "@/components/user-profile/ChangePassword.vue";
import { canUseTrademark } from "@/services/enabled_features.js";

const navigation = [
  {
    name: "Dashboard",
    href: ``,
    icon: HomeIcon,
    current: false,
  },
  {
    name: "Members",
    href: "members",
    icon: UsersIcon,
    current: false,
  },
  {
    name: "Patent Applications",
    href: "patents",
    icon: FolderIcon,
    current: false,
  },
  {
    name: "Clients",
    href: "clients",
    icon: OfficeBuildingIcon,
    current: false,

  },
  {
    name: "Docketing",
    dashboardName: "Your Docketing Tasks",
    href: "docketing",
    icon: CalendarIcon,
    current: false,
  },
  {
    name: "Office Actions",
    href: "office-actions",
    icon: InboxIcon,
    current: false,
  },
  {
    name: "Settings",
    href: "settings",
    icon: CogIcon,
    current: false,
  },
  {
    name: "Help",
    href: "help",
    icon: QuestionMarkCircleIcon,
    current: false,
  },
];
const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Change Password", href: "#" },
  { name: "Switch Firm", href: "#" },
  { name: "Sign Out", href: "#" },
];

export default {
  components: {
    ChangePassword,
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuAlt2Icon,
    XIcon,
    ProfileView,
    Avatar,
    BadgeCheckIcon
  },
  async mounted() {
    console.log("dashboardlayout.vue called ...");
    this.firmId = this.$route.path.split("firm/").pop().split("/")[0];
    const firm = await getFirm(this.firmId).catch(() => { this.notFound = true; });
    this.firmName = firm.name;
    this.uid = this.$cookies.get("uid");
    this.user = await getUser(this.uid);
    this.role = (await getMember(this.firmId, this.uid)).role;
    if ((await canUseTrademark(this.firmId)) && this.navigation[6].name != "Trademarks") {
      this.navigation.splice(6, 0, {
        name: "Trademarks",
        href: "trademarks",
        icon: BadgeCheckIcon,
        current: false,
      },)
    }
  },
  computed: {
    current() {
      return this.$route.href;
    },
    currentRouteName() {
      return this.$route.name;
    },
    firstName() {
      if (!this.user) {
        return "";
      }
      return capitalize(this.user.firstName);
    },
  },
  data: () => {
    return {
      uid: null,
      user: null,
      profilePopup: false,
      passwordPopup: false,
      firmId: null,
      firmName: "",
      role: "",
      key: 0,
      notFound: false,
    };
  },
  setup() {
    const sidebarOpen = ref(false);
    return {
      navigation,
      userNavigation,
      sidebarOpen,
    };
  },
  methods: {
    async profileFunction(data) {
      if (data == userNavigation[0].name) {
        this.profilePopup = true;
      } else if (data == userNavigation[1].name) {
        this.passwordPopup = true;
      } else if (data == userNavigation[2].name) {
        this.$cookies.remove("defaultFirm");
        this.$cookies.remove("teams");
        await updateUser(this.$cookies.get("uid"), { defaultFirm: "" });
        this.$router.push("/dashboard");
      } else if (data == userNavigation[3].name) {
        this.signOut();
      }
    },
    closeProfile() {
      this.profilePopup = false;
      this.key++;
    },
    async signOut() {
      await logout(this.loginForm);
      this.$cookies.remove("uid");
      this.$cookies.remove("teams");
      this.$cookies.remove("defaultFirm");
      this.$router.push("/signin");
    },
    slash(name) {
      if (name == "Dashboard") {
        return "";
      }
      return "/";
    },
  },
};
</script>
