import { db, auth } from "./db.js";
import { acceptInvite } from "./backend-api.js";
import firebase from "firebase/app";

// ==================== CREATE ====================

async function createUser(uid, data) {
  console.log("createuser called");
  data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
  var result = await db
    .collection("users")
    .doc(uid)
    .set(
      {
        ...data,
      },
      { merge: true }
    );
  return result;
}

function addFirm(uid, firmId, role, firmName) {
  return db.doc(`users/${uid}/firms/${firmId}`).set({
    firmId,
    status: "joined",
    role,
    name: firmName,
  });
}

// ==================== READ ====================

async function getUser(uid) {
  console.log("getuser called");
  try {
    const user = await db.doc(`users/${uid}`).get();
    return user.exists ? user.data() : null;
  } catch(err) {
    return null;
  }
}

function currentUser() {
  return auth.currentUser;
}

async function getUserByEmail(email) {
  const querySnapshot = await db
    .collection(`users`)
    .where("email", "==", email)
    .get();
  let users = [];
  querySnapshot.forEach((user) => users.push(user));
  return users;
}

// ==================== UPDATE ====================

function updateUser(uid, data) {
  console.log("updating user!" + data.lastName);
  let member_data = {};
  if (Object.prototype.hasOwnProperty.call(data, "firstName")) {
    member_data.firstName = data.firstName;
  }
  if (Object.prototype.hasOwnProperty.call(data,"lastName")) {
    member_data.lastName = data.lastName;
  }
  if (Object.prototype.hasOwnProperty.call(data, "phoneNumber")) {
    member_data.phoneNumber = data.phoneNumber;
  }

  return Promise.all([
    db.doc(`users/${uid}`).update(data),
    updateMemberships(uid, member_data),
  ]).then(() => data);
}

function updateMemberships(uid, data) {
  return db
    .collection(`users/${uid}/firms`)
    .get()
    .then((querySnapshot) => {
      return querySnapshot.forEach((firm) => {
        console.log("update firm member" + firm.id);
        return db.doc(`firms/${firm.id}/members/${uid}`).update(data);
      });
    });
}

// ==================== AUTHENTICATION ====================

async function signUp(form) {
  console.log("signup called");
  return await auth.createUserWithEmailAndPassword(form.email, form.password);
}

function login(form) {
  return auth.signInWithEmailAndPassword(form.email, form.password);
}

function logout() {
  console.log("logout called");
  return auth.signOut();
}

async function resetPassword(old_password, new_password)
{
  var user = currentUser();
  if (!user) {
    return false;
  }
  var cred = firebase.auth.EmailAuthProvider.credential(
    user.email,
    old_password
  );
  var userCred = await user.reauthenticateWithCredential(cred);
  if (userCred.user) {
    await userCred.user.updatePassword(new_password);
    return true
  } else {
    return false
  }
}

function consumeInviteCookies(cookies) {
  const invite_id = cookies.get("patensys_invite_id");
  const firm_id = cookies.get("patensys_invited_firm_id");
  if (invite_id && firm_id) {
    acceptInvite(invite_id, firm_id);
  }
  cookies.remove("patensys_invite_id");
  cookies.remove("patensys_invited_firm_id");
}

function userEmailVerification() {
  if (auth.currentUser) {
    return auth.currentUser.sendEmailVerification({url: window.location.href})
    .then(() => {
      console.log("email verification sent");
    });
  }
}

export {
  addFirm,
  consumeInviteCookies,
  createUser,
  currentUser,
  getUser,
  getUserByEmail,
  login,
  logout,
  resetPassword,
  signUp,
  updateUser,
  userEmailVerification,
};
