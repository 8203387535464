import axios from "axios";
//import axiosRetry from "axios-retry";

axios.defaults.baseURL = "/api/";
if (location.hostname === "localhost") {
  axios.defaults.baseURL = "http://localhost:3000";
}
axios.defaults.timeout = 600000;

export function createClient(token) {
  const instance = axios.create({
    headers: {
      token: token,
    },
  });

  //axiosRetry(instance, { retryDelay: axiosRetry.exponentialDelay });

  instance.interceptors.response.use((value) => {
    return value.data;
  });
  return instance;
}
